import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import styles from './stlyes'

interface IProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<IProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null

  return (
    <div style={styles.overlay} /*onClick={onClose}*/>
      <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
        <Button type="primary" style={styles.closeButton} onClick={onClose}>
          <CloseOutlined style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }} />
        </Button>
        {children}
      </div>
    </div>
  )
}

export default Modal
