import { useEffect, useLayoutEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Image } from 'antd'

import Modal from 'src/shared/components/Modal'

import App from 'src/layouts/App'
import { WhatsAppFloat } from './Whatsapp'

export const Root = () => {
  const { pathname } = useLocation()
  const [isOpen, setOpen] = useState<boolean>(false)

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    setTimeout(() => {
      // setOpen(false)
    }, 3000)
    if (pathname == '/' || pathname == '') setOpen(true)
  }, [])

  return (
    <App hasNavbar={pathname != '/register'} hasFooter={pathname != '/register'}>
      <Outlet />
      {pathname != '/register' && <WhatsAppFloat />}

      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <Image width={300} src={'images/notification.png'} preview={false} />
      </Modal>
    </App>
  )
}
